import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import AutoAtendimento from './pages/Auto Atendimento/AutoAtendimento';
import Webmail from './pages/Webmail/Webmail';
import PortalCultura from './pages/Portal Cultura/PortalCultura';
import IPTU from './pages/IPTU/IPTU';
import Footer from './components/Footer';
import logo from './img/brazaomenor.png';
import Navbar  from './components/Navbar';



function App() {

  setTimeout(function() {
    window.location.href="https://oliveira.atende.net/cidadao"

  }, 40000);

  
  return (

    <div className="App">
     
      <img src={logo} alt=""/>
      
        <BrowserRouter>
      
          <Navbar/>
              <Routes>
                <Route path="/iptu" element={<IPTU/>} />
                <Route path="/autoatendimento" element={<AutoAtendimento/>} />
                <Route path="/webmail" element={<Webmail/>} />
                <Route path="/portalcultura" element={<PortalCultura/>} />
              </Routes>
              <Footer/>
              
        </BrowserRouter>
        </div>
    
  );
};

export default App;
