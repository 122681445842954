import logo from "../img/print.png";
import styles from "./Footer.module.css";

const Footer = () => {
  return (
     <footer class={styles.footer}>
       <img src={logo} alt=""/>

     </footer>
   
    );
};

export default Footer;