import { NavLink } from "react-router-dom";
import logo from "../img/brazao.png";
import styles from "./Navbar.module.css";

/*<NavLink to= "/" className={styles.brand}>
<img src={logo} alt="" />
</NavLink> <---- alteração codigo*/

const Navbar = () => {
   return (
     <nav className={styles.navbar}>
       
       <ul className={styles.links_list}>
          <li>
             <NavLink className={styles.brand}
               to="https://oliveira.atende.net/autoatendimento/servicos/guias-de-iptu/detalhar/1"
             >
              IPTU
             </NavLink>
          </li>
          <li>
             <NavLink  className={styles.brand} 
             to="https://oliveira.atende.net/cidadao"
             >
              Auto Atendimento
             </NavLink>
          </li>
          <li>
             <NavLink  className={styles.brand} 
             to="https://webmail-seguro.com.br/oliveira.mg.gov.br/"
             >
              Webmail
             </NavLink >
          </li>
          <li>
             <NavLink  className={styles.brand}
              to="https://oliveira.atende.net/subportal/portal-da-cultura"
             >
              Portal da Cultura
             </NavLink>
          </li>
       </ul>
     </nav>
   );
};

export default Navbar;